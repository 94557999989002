import { APIFilter } from '@/utils/api'

export default {
  async selectDetail (Vue, filter, search, sorter, page, idordenTrabajo, idsubsis) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idorden_trabajo', idordenTrabajo)
      .addExact('idsubsis', idsubsis)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.materialSistema.searchFilter(search))
    }
    apiCalls.push({
      name: 'selectOrdenTrabajoMatsist',
      method: 'ordenTrabajoMatsist.select',
      params: { page, filter: apiFilter, sorter },
    })
    const apiFilterIdorden = new APIFilter()
      .addExact('idorden_trabajo', idordenTrabajo)
      .addGT('estado', 0)
    apiCalls.push({
      name: 'selectAccionEjec',
      method: 'accionEjec.select',
      params: { filter: apiFilterIdorden },
    })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectOrdenTrabajoMatsistRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idorden_trabajo_matsist', pks)
    const resp = await Vue.$api.call('ordenTrabajoMatsist.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
